<template>
  <div class="bg bg-dark pa-0" id="snapwidget">
    <iframe
      src="https://snapwidget.com/embed/932255"
      class="snapwidget-widget"
      allowtransparency="true"
      frameborder="0"
      scrolling="no"
      style="border: none; overflow: hidden; width: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "InstagramFeed",
  mounted() {
    let snapWidgetScript = document.createElement("script");
    snapWidgetScript.setAttribute(
      "src",
      "https://snapwidget.com/js/snapwidget.js"
    );
    var container = document.getElementById("snapwidget");
    container.prepend(snapWidgetScript);
  },
};
</script>
