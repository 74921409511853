<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-light" id="start">
      <v-row>
        <v-col :cols="12" :md="6">
          <h2>5 Reasons why you should join Q</h2>
          <p style="width:95%;">
            Q is a special place to work, made up of a passionate team who
            deliver brilliance and exceptional service to our clients. Our
            people are very important to us and we like to look after them by
            providing a range of opportunities and benefits.
          </p>
          <v-img style="width:90%;" src="@/assets/images/careers@Q.jpg"/>
        </v-col>
        <v-col :cols="12" :md="6">
          <h4>Be better at what you do</h4>
          <ul>
            <li>
              We believe that our people are our greatest achievement and know
              our team is our greatest asset
            </li>
            <li>
              We regularly show our appreciation to our people and celebrate
              their achievements.
            </li>
          </ul>
          <br><br>
          <h4>You are our story</h4>
          <ul>
            <li>
              We believe that our people are our greatest achievement and know our team is our greatest asset            
            </li>
            <li>
              We regularly show our appreciation to our people and celebrate their achievements.
            </li>
          </ul>
          <br><br>
           <h4>Flexible fun work space</h4>
          <ul>
            <li>
              Our people enjoy coming into work and spending time with each other.
            </li>
            <li>
              We offer flexibility to help our people get the balance right between life in and outside of work.
            </li>
            <li>
              We are a team of professionals who know what it takes to get the job done.  
              But we also know how and when to have fun.
            </li>
          </ul>
          <br><br>
           <h4>Be part of something bigger</h4>
          <ul>
            <li>
              We embrace diversity and inclusion.  Our team is proudly made up of people from across
              the globe and we see this as one of our greatest strengths.  
            </li>
            <li>
              We regularly show our appreciation to our people and celebrate
              their achievements.
            </li>
          </ul>
          <br><br>
           <h4>Create impact</h4>
          <ul>
            <li>
              We deliver campaigns for our clients that impact their business and your friends 
              will be talking about at the water cooler
            </li>
            <li>
              We care about the community we live in and are passionate about giving back.  
              Through our Q Cares program we show our support for causes that we’re passionate about.
            </li>
          </ul>
          <br><br>
           <h4>Get In Touch With Us</h4>
          <ul>
            <li class="email-career">
              Email: Kate Burke @ kate@qcommunications.co.uk 
            </li>
          </ul>
        </v-col>
      </v-row>
     
    </container>
    <container type="bg-light" class="pb-16">
      <h2 class="text-center pt-16">We Live &amp; Breath Our Values</h2>
      <v-row dense class="justify-center">
        <v-col  style="padding-bottom:120px;"
          v-for="(item, id) in values"
          :key="id"
          :xs="6"
          :sm="4"
          :md="3"
          :lg="2"
        >
          <v-card class="bg-transparent" tile elevation="0">
            <v-img :src="item.image" :alt="item.title" contain height="100px" />
            <v-card-text class="font-weight-bold text-uppercase text-center">{{
              item.title
            }}</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </container>
    <container type="bg-dark">
      <v-row style="margin-top:88px;">
        <v-col class="text-center">
          <h2 class="text-center">Life @ Q</h2>
          <p>
            We're committed to making Q the most inspiring, engaged, inclusive
            and productive workplace in the world. Take a look at how we bring
            Q's culture to life - community is everything for us and there's
            always something for everyone.
          </p>
          <br>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col :md="6" order="md-first">
          <v-img src="@/assets/images/life@Q_celebrate.jpg" />
        </v-col>
        <v-col :md="6" order="md-last">
          <h4>We love to celebrate</h4>
          <p>
            We really enjoy spending time together at work and out of work. Our
            social committee has a host of monthly team activities, special
            treats and events to make our time together even more special.
          </p>
          <ul>
            <li>
              We celebrate all special days from Valentines to Halloween and
              just about everything in between.
            </li>
            <li>
              Q Fairies come out once a year to help us pay it forward across
              the full team.
            </li>
            <li>
              We celebrate and share everything from the month before and month
              ahead in our Monthly Team Meetings.
            </li>
            <li>
              Birthdays are marked with specialty cakes and Qversaries with
              lunch on Q.
            </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
      <v-row class="align-center">
        <v-col :md="6" order="md-last">
          <v-img src="@/assets/images/life@Q_appreciate.jpg" />
        </v-col>
        <v-col :md="6" order="md-first">
          <h4>We appreciate you</h4>
          <p>
            Our people are our greatest achievement and we make sure we show
            them just how special they are with a wide range of rewards and
            recognition:
          </p>
          <ul>
            <li>
              Monthly: Outstanding Contribution Award - nominated by the team
              themselves
            </li>
            <li>
              Monthly: Qcademy Graduation 
            </li>
            <li>
              Quarterly: Employee of the Quarter
            </li>
            <li>
              Yearly: Employee of the Year 
            </li>
            <li>
              Yearly: Q’er of the Year
              </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
      <v-row class="align-center">
        <v-col :md="6" order="md-first">
          <v-img src="@/assets/images/life@Q_fun.jpg" />
        </v-col>
        <v-col :md="6" order="md-last">
          <h4>Flexible Fun Work Space</h4>
          <p>
           At Q Communications we recognize that our lives are made up of responsibilities
            and opportunities in and outside of work.  We ask that our people give us their
            absolute best at work.  One of the things we like to provide to our people in 
            exchange is flexibility. 
          </p>
          <ul>
            <li>
              Flexible start and finish times
            </li>
            <li>
              An annual ‘work from anywhere’ week
            </li>
            <li>
              A business-wide long weekend every month that makes up 6 extra days leave per year 
            </li>
            <li>
              Working from home days for senior managers.
            </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
      <v-row class="align-center">
        <v-col :md="6" order="md-last">
          <v-img src="@/assets/images/life@Q_qacademy.jpg" />
        </v-col>
        <v-col :md="6" order="md-first">
          <h4>Qcademy will help you grow</h4>
          <p>
            We value continuous learning and have an internal Qcademy to help
            everyone develop their skills. We also offer a mentorship program 
            and personalised training scholarships. 
          </p>
          <ul>
            <li>
              Online academy with over 100 courses available 
            </li>
            <li>
              Fun and interactive videos and tasks that you can earn points with  
            </li>
            <li>
              Customised learning and development plans 
            </li>
            <li>
              Specialist leadership program  
            </li>
            <li>
              Specialist one on one training sessions
              </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
      <v-row class="align-center">
        <v-col :md="6" order="md-first">
          <v-img src="@/assets/images/life@Q_grow.jpg" />
        </v-col>
        <v-col :md="6" order="md-last">
          <h4>Workspace for you to grow </h4>
          <p>
           We pride ourselves on our teams personal and professional development. 
           At Q we want to offer every employee the best possible work space and 
           environment possible for them to be able to deliver brilliance for their clients.  
          </p>
          <ul>
            <li>
              We listen to you. We have a dedicated suggestion box for you to share your ideas. 
            </li>
            <li>
              Bi-yearly employee survey to make sure we know what you need and what you want more of. 
            </li>
            <li>
              Enjoy dedicated kitchens and pantries stocked with fresh fruit, a wide coffee & tea 
              selection with vegan friendly options.  
            </li>
            <li>
              We promise to give you all the equipment and tools you need to be able to be the 
              absolute best at what you do.
            </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
      <v-row class="align-center">
        <v-col :md="6" order="md-last">
          <v-img src="@/assets/images/life@Q_qcares.jpg" />
        </v-col>
        <v-col :md="6" order="md-first">
          <h4>Q Cares</h4>
          <p>
            At Q, we take being a good human seriously. We’re always on the 
            lookout for opportunities to support causes we care about locally and across the globe. 
          </p>
          <ul>
            <li>
              We offer all Q team members one week volunteering leave in addition to 
              their annual leave to support a local or global charity of their choice. 
            </li>
          </ul>
        </v-col>
      </v-row>
      <br><br><br>
    </container>
    <container type="bg-light">
      <h2 class="text-center">Open positions @ Q</h2>
      <v-row dense class="justify-center">
        <v-col>
          <div id="BambooHR">
            <div id="BambooHR-Footer">
              Powered by<a
                href="http://www.bamboohr.com"
                target="_blank"
                rel="noopener external nofollow noreferrer"
              >
                <img
                  src="https://resources.bamboohr.com/images/footer-logo.png"
                  alt="BambooHR - HR software"
              /></a>
            </div>
          </div>
        </v-col>
      </v-row>
    </container>
    <instagram-feed />
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import InstagramFeed from "../components/InstagramFeed.vue";

export default {
  name: "Careers",
  components: {
    vHeader,
    InstagramFeed,
  },
  mounted() {
    let bambooHRScript = document.createElement("script");
    bambooHRScript.setAttribute(
      "src",
      "https://qcomms.bamboohr.com/js/jobs2.php"
    );
    let container = document.getElementById("BambooHR");
    container.prepend(bambooHRScript);
  },
  data() {
    return {
      image: require("@/assets/backgrounds/about-1.jpg"),
      overlayCopy: [
        {
          copy: "Careers @ Q",
          size: "50",
        },
      ],
      digitalServices: ["Google Ads", "Youtube", "PPC Campaigns", "Metasearch"],
      values: [
        {
          title: "Transparency",
          image: require("@/assets/icons/careers_transparency.png"),
        },
        {
          title: "Creativity",
          image: require("@/assets/icons/careers_creativity.png"),
        },
        {
          title: "Communication",
          image: require("@/assets/icons/careers_communication.png"),
        },
        {
          title: "Respect",
          image: require("@/assets/icons/careers_respect.png"),
        },
        {
          title: "Passion",
          image: require("@/assets/icons/careers_passion.png"),
        },
      ],
    };
  },
};
</script>
